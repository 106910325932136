.Nav
{
    width: 100%;
    height: 80px;
    line-height: 80px;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #0a071a;
    z-index: 9999999999999;
  
   
}
.navigation
{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.nav-list
{
    display: flex;
    align-items: center;
    margin-bottom: 0px;
    list-style: none !important;

    column-gap: 2.7rem;

}

.Nav h2 
{
    color: #f5b70a;
    margin-bottom: 0;
}
.nav-item a 
{
    text-decoration: none;
cursor: pointer;
color: white;

}
.nav-item a:hover
{
    color: #f5b70a;
}
.custom-search
{
    display: flex;
    align-items: center;
    padding: 10px 25px;
    border-radius: 50px;
    height: 35px;
    background-color: #141124;
}
.custom-search input
{
    outline: none;
    border: none;
    background-color: transparent;
    color: white;
}
.custom-search span
{
    cursor: pointer;
}
.menu-mobile span i 
{
font-size: 1.5rem;
color: white !important;
}
.menu-mobile 
{
    display: none;
}
        /* media querry */
@media screen and (max-width:992px) {
    .nav-menu
    {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 99999999999999999;
        background-color: #0a071a7e;
        display: none;
    }
    .nav-list-wrapper{
position: absolute;
top: 0;
right:  0;
width: 250px;
height: 100%;
z-index: 999999999999999999999999999999;
background-color: #141124;
flex-direction: column;
box-shadow:  5px 5px 15px -5px #f5b70a;
justify-content:center!important;
padding: 0px 20px;
    }
    .nav-list 
    {
        flex-direction: column;
        padding: 0%;
    }
    .nav-item a 
    {
        font-size: 0.9rem;

    }
.Nav{
    line-height: 40px;
    padding: 10px 0px;
    width: 100%;
   
}    
.Active-menu{
    display: block;
}
.logo h2 
{
    font-size: 1.5rem;
}
.custom-search {
    border: 1px solid #c4c4c425;
    padding: 5px 15px;
    font-size: 0.9rem;
    margin-top: 20px;
    height: 30px;
}
.menu-mobile 
{
    display: block;
}
            
        }