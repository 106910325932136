.FullMenu-parent 
{
    margin-top: 120px;
    margin-bottom: 30px;
}
.FullMenu-parent .single-product
{
    margin-bottom: 40px;
}
.FullMenu-parent .filter-btn
{
    border : 1px solid white;
    padding: 7px 25px;
    background-color: transparent;
    color: white;
    cursor: pointer;
    margin-left: 15px;
    border-radius: 5px;
    background-color: transparent;
    text-transform: capitalize;
    margin-bottom: 20px;
}
/* .active-menu 
{
    background-color: #f76e11 !important;
     opacity: 0.6; 
} */
.FullMenu-parent .menu-title 
{
    color: #f5b70a;
}
.FullMenu-parent .btn 
{
    padding: 7px 25px;
    background-color: #f76e11;
    color: white;
    font-size: 0.9rem;
    text-transform: capitalize;
    margin-left: 5px;
    margin-top: 30px;
}
.FullMenu-parent .Load-more
{
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    color:white;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    letter-spacing: 0.05em;
    user-select: none;
    background-color: #f76e11; 
     border: 1px solid transparent;
    font-size: 16px;
     border-radius: 10px; 
     width: 200px;
     text-align: center;
     padding: 0.375rem 0rem;
}
.FullMenu-parent .btn:hover 
{

    background-color: #f76e11;
    color: black;
  
}
