.parent
{
   /* margin-top: 8rem !important; */
   padding-top: 40px;
   margin-bottom: 6rem !important;
/* display: flex;
justify-content: center;
align-items: center;     */
}

.slider__wrapper h2 
{

    color: #f5b70a;
    line-height: 1.5;
    text-transform: capitalize;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;


}
.slider__wrapper p 
{
    color: white;
    font-size: 15px;
    line-height: 1.9;

}
.slider__wrapper .btn 
{
    padding: 7px 25px;
    background-color: #f76e11;
    color: white;
    font-size: 0.9rem;
    margin-top: 30px;
}
.slick-prev {
    left: -50px ;
}
.slider__wrapper .btn:hover 
{
   color: black;
   background-color: #f76e11;
}
@media screen and (max-width:1024px) and (min-width:992) {
    .slick-prev {
        left: -25px !important;
    }
    
}
@media screen and (max-width : 992px) and (min-width:700px)  {
   .slider__wrapper h2 
   {
    font-size: 20px;
   } 
   .slider__wrapper p 
   {
    font-size: 10px;
   }
   .slider__wrapper .btn 
   {
    margin-top: 0px;
   }
    
}
@media screen and (max-width:580px) {
    .slider__wrapper 
    {
        margin: 0px 27px;
    }
    .slider__wrapper h2 
    {
        font-size: 12px;

    }
    .slider__wrapper p 

    {
        font-size: 7px;
    }    
    .slick-next
    {
       right: 2px !important;
    }
    .slick-prev
    {
     left: -1px !important;
    }
}
@media screen and (max-width:390) {
    
    
}
              /* media query */
/* @media screen and (max-width:1024px ) and (min-width:990px ) {
    .slick-prev, .slick-next
    {
        left: 94%;
    }
    
}
@media screen and (max-width:990px ) and (min-width:540px ) {
    .slick-prev {
        left: -29px !important;
    }   
   .slick-next

    {
        left: 100%;
    }
    .slider__wrapper h2 
    {
        font-size: 20px;
    }
    .slider__wrapper p 
    {
        font-size: 10px;
    }
    
}
@media screen and (max-width:540px ) and (min-width:412px ) {
    .slick-prev, .slick-next
    {
        left: 97%;
    }
    .slider-content
    {
        width: 40% !important; 
        margin-left: 20px;
    }
    .slider-img
    {
        width: 40% !important;
    }
    .slider__wrapper h2 
    {
        font-size: 10px;
    }
    .slider__wrapper p 
    {
        font-size: 7px;
    }
    .slick-prev {
        left: -5px !important;
    }
    .slider-img img 
    {
        width: 90% !important;
    }
    .slider__wrapper .btn 
    {
        margin-top: 20px;
    }
    
}
@media screen and (max-width:490px )  {

    .slick-prev, .slick-next
    {
        left: 97%;
    }
    .slider-content
    {
        width: 40% !important; 
        margin-left: 20px;
    }
    .slider-img
    {
        width: 40% !important;
    }
    .slider__wrapper h2 
    {
        font-size: 10px;
    }
    .slider__wrapper p 
    {
        font-size: 7px;
    }
    .slick-prev {
        left: -5px !important;
    }
    .slider-img img 
    {
        width: 90% !important;
    }
    .slider__wrapper .btn 
    {
        margin-top: 20px;
    }
    
}
@media screen and (max-width:280px )  {
    

    .slick-prev, .slick-next
    {
        left: 97%;
    }
    .slider-content
    {
        width: 100% !important; 
        margin-left: 20px;
    }
    .slider-img
    {
        width: 100% !important;
    }
    .slider__wrapper h2 
    {
        font-size: 7px;
    }
    .slider__wrapper p 
    {
        font-size: 5px;
    }
    .slick-prev {
        left: -5px !important;
    }
    .slider-img img 
    {
        width: 100% !important;
    }
    .slider__wrapper .btn 
    {
padding: 2px 10px;
        margin-top: 10px;
    }
    
}
 */
